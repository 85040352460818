import React, { useEffect, useState } from 'react';
import { Grid, MenuItem, CircularProgress, Button } from '@mui/material';
import { useBigQueryApi } from '@/api-client/big-query-api';
import { useConfigurationsApi } from '@/api-client/configurations-api';
import {
    setCurrentConfigurationBQColumns,
    setCurrentConfigurationBQDataset,
    setCurrentConfigurationBQTable,
    setCurrentConfigurationColumns,
} from '@/store/configurationsSlice';
import { AppDispatch } from '@/store';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import IconButton from '@mui/material/IconButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import debounce from 'lodash/debounce';
import CustomSelect from '@/components/custom-select';
import { ConfigurationsList } from '@/types';

interface Props {
    isDraft: boolean;
    configurationId: string | null;
    configuration: ConfigurationsList;
}

const BigQueryOptions: React.FC<Props> = ({ isDraft, configurationId, configuration }) => {
    const dispatch: AppDispatch = useDispatch();
    const { getBigQueryDatasets, getBigQueryDatasetTables, getBigQueryDatasetTableProperties } = useBigQueryApi();
    const { postDraftConfigurationBigQueryMappingSet, postConfigurationBigQueryMappingSet } = useConfigurationsApi();
    const { enqueueSnackbar } = useSnackbar();

    const [datasets, setDatasets] = useState<string[]>([]);
    const [tables, setTables] = useState<string[]>([]);
    const [tableProperties, setTableProperties] = useState<{ name: string; type: string; mode: string }[]>([]);
    const [selectedDataset, setSelectedDataset] = useState<string>('');
    const [selectedTable, setSelectedTable] = useState<string>('');

    const [loadingDatasets, setLoadingDatasets] = useState<boolean>(false);
    const [loadingTables, setLoadingTables] = useState<boolean>(false);
    const [loadingProperties, setLoadingProperties] = useState<boolean>(false);
    const [tableError, setTableError] = useState<string | null>(null);
    const [datasetError, setDatasetError] = useState<string | null>(null);
    const [applyStatus, setApplyStatus] = useState<'loading' | 'success' | 'error' | null>(null);

    const fetchDatasets = async (): Promise<void> => {
        setLoadingDatasets(true);
        try {
            const data = await getBigQueryDatasets({});
            setDatasets(data);
        } catch (error) {
            console.error('Failed to fetch datasets:', error);
        } finally {
            setLoadingDatasets(false);
        }
    };

    useEffect(() => {
        fetchDatasets();
    }, []);

    const handleDatasetChange = async (event: React.ChangeEvent<{ value: unknown }>): Promise<void> => {
        const datasetId = event.target.value as string;
        setSelectedDataset(datasetId);
        setSelectedTable('');
        setTableProperties([]);
        setDatasetError(null);
        setTableError(null);
        setApplyStatus(null);

        setLoadingTables(true);
        try {
            const data = await getBigQueryDatasetTables(datasetId);
            setTables(data);
            dispatch(setCurrentConfigurationBQDataset(datasetId));
        } catch (error) {
            setDatasetError('Failed to fetch tables');
            enqueueSnackbar('Failed to fetch tables', { variant: 'error', preventDuplicate: true });
            console.error('Failed to fetch tables:', error);
        } finally {
            setLoadingTables(false);
        }
    };

    const handleTableChange = async (event: React.ChangeEvent<{ value: unknown }>): Promise<void> => {
        const tableId = event.target.value as string;
        setSelectedTable(tableId);
        setTableError(null);
        setApplyStatus(null);

        setLoadingProperties(true);
        try {
            const data = await getBigQueryDatasetTableProperties(selectedDataset, tableId);
            setTableProperties(data);
            dispatch(setCurrentConfigurationBQTable(tableId));
        } catch (error) {
            if (error?.statusCode === 400) {
                setTableError(error?.message || 'Table schema contains unsupported field type');
                enqueueSnackbar(`${error?.message || 'Table schema contains unsupported field type'}`, {
                    variant: 'error',
                    preventDuplicate: true,
                });
            } else {
                console.error('Failed to fetch table properties:', error);
            }
        } finally {
            setLoadingProperties(false);
        }
    };

    const debouncedHandleApply = debounce(async (): Promise<void> => {
        const payload = {
            dataset: selectedDataset,
            table: selectedTable,
            properties: tableProperties,
        };

        setApplyStatus('loading');
        try {
            if (isDraft && configurationId) {
                await postDraftConfigurationBigQueryMappingSet(configurationId, payload);
                dispatch(setCurrentConfigurationBQColumns(tableProperties));
            } else if (configurationId) {
                await postConfigurationBigQueryMappingSet(configurationId, payload);
                dispatch(setCurrentConfigurationBQColumns(tableProperties));
            }
            const updatedColumns = configuration?.columns?.map((column) => ({
                ...column,
                bigQueryField: null,
            }));

            // Dispatch updated columns
            dispatch(setCurrentConfigurationColumns(updatedColumns));
            setApplyStatus('success');
        } catch (error) {
            setApplyStatus('error');
            if (error?.details?.violations) {
                error.details.violations.violations.forEach((violation: unknown) => {
                    enqueueSnackbar(`${violation.propertyPath}: ${violation.title}`, {
                        variant: 'error',
                        preventDuplicate: true,
                    });
                });
            } else {
                enqueueSnackbar(`${error?.title || 'Error occurred'}`, { variant: 'error', preventDuplicate: true });
            }
            console.error('Failed to apply configuration:', error);
        }
    }, 300);

    return (
        <>
            <Grid item xs={12}>
                <h4>BQ Mapping *:</h4>
            </Grid>
            <Grid item xs={6}>
                <CustomSelect
                    status={datasetError ? 'error' : null}
                    label="Dataset"
                    renderValue={(value) => (value !== '' ? value : <em>{configuration.bigQuery.dataset ?? ''}</em>)}
                    value={selectedDataset || ''}
                    onChange={handleDatasetChange}
                    disabled={loadingDatasets}
                    helperText={datasetError}
                >
                    {loadingDatasets ? (
                        <MenuItem value="">
                            <CircularProgress size={24} />
                        </MenuItem>
                    ) : (
                        datasets.map((dataset) => (
                            <MenuItem key={dataset} value={dataset}>
                                {dataset}
                            </MenuItem>
                        ))
                    )}
                </CustomSelect>
            </Grid>
            <Grid item xs={6}>
                <CustomSelect
                    status={tableError ? 'error' : null}
                    label="Table"
                    renderValue={(value) => (value !== '' ? value : <em>{configuration.bigQuery.table ?? ''}</em>)}
                    value={selectedTable || ''}
                    onChange={handleTableChange}
                    disabled={loadingTables || !selectedDataset}
                    helperText={tableError}
                >
                    {loadingTables ? (
                        <MenuItem value="">
                            <CircularProgress size={24} />
                        </MenuItem>
                    ) : (
                        tables.map((table) => (
                            <MenuItem key={table} value={table}>
                                {table}
                            </MenuItem>
                        ))
                    )}
                </CustomSelect>
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={debouncedHandleApply}
                    disabled={
                        !selectedDataset ||
                        !selectedTable ||
                        loadingProperties ||
                        Boolean(datasetError) ||
                        Boolean(tableError)
                    }
                >
                    Apply
                </Button>
                {applyStatus === 'loading' && <CircularProgress size={24} />}
                {applyStatus === 'success' && (
                    <IconButton edge="end" disableRipple>
                        <CheckCircleIcon color="success" />
                    </IconButton>
                )}
                {applyStatus === 'error' && (
                    <IconButton edge="end" disableRipple>
                        <HighlightOffIcon color="error" />
                    </IconButton>
                )}
            </Grid>
        </>
    );
};

export default BigQueryOptions;
