import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@/store';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    TableSortLabel,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    TextField,
    CircularProgress,
} from '@mui/material';
import debounce from 'lodash/debounce';
import {
    getBusinessTeamsListFromState,
    setBusinessTeamsFullList,
    setBusinessTeamsList,
} from '@/store/businessTeamsSlice';
import { useBusinessTeamsApi } from '@/api-client/business-teams-api';
import { useSnackbar } from 'notistack';
import CustomButton from '@/components/custom-button';

const BusinessTeams: React.FC = () => {
    const { getBusinessTeamsList, createBusinessTeam, updateBusinessTeam, revokeBusinessTeam, restoreBusinessTeam } =
        useBusinessTeamsApi();
    const dispatch: AppDispatch = useDispatch();
    const businessTeamsList = useSelector(getBusinessTeamsListFromState);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sortBy, setSortBy] = useState<string>('id');
    const [sortDir, setSortDir] = useState<'asc' | 'desc'>('desc');
    const [open, setOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [teamName, setTeamName] = useState('');
    const [editTeamId, setEditTeamId] = useState<number | null>(null);
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(true);

    const handleChangePage = (event: unknown, newPage: number): void => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSortChange = (property: string): void => {
        const isAsc = sortBy === property && sortDir === 'asc';
        setSortDir(isAsc ? 'desc' : 'asc');
        setSortBy(property);
    };

    const fetchBusinessTeamsList = async (
        currentPage: number,
        currentRowsPerPage: number,
        currentSortBy: string,
        currentSortDir: string,
    ): Promise<void> => {
        setLoading(true);
        try {
            const list = await getBusinessTeamsList({
                page: currentPage,
                perPage: currentRowsPerPage,
                sortBy: currentSortBy,
                sortDir: currentSortDir,
            });
            dispatch(setBusinessTeamsList(list));
        } catch (error) {
            console.error('Error fetching business teams list:', error);
        } finally {
            setLoading(false);
        }
    };

    const debouncedFetchBusinessTeamsList = useCallback(
        debounce((currentPage: number, currentRowsPerPage: number, currentSortBy: string, currentSortDir: string) => {
            fetchBusinessTeamsList(currentPage, currentRowsPerPage, currentSortBy, currentSortDir);
        }, 500),
        [],
    );

    useEffect(() => {
        debouncedFetchBusinessTeamsList(page + 1, rowsPerPage, sortBy, sortDir);
    }, [page, rowsPerPage, sortBy, sortDir]);

    const handleOpenModal = (isEditing: boolean, teamName: string = '', teamId: number | null = null): void => {
        setIsEditing(isEditing);
        setTeamName(teamName);
        setEditTeamId(teamId);
        setOpen(true);
    };

    const handleCloseModal = (): void => {
        setOpen(false);
        setTeamName('');
        setEditTeamId(null);
    };

    const handleSave = async (): void => {
        try {
            if (isEditing && editTeamId) {
                await updateBusinessTeam(editTeamId, { name: teamName });
                enqueueSnackbar(`Business team updated successfully`, {
                    variant: 'success',
                    preventDuplicate: true,
                });
            } else {
                await createBusinessTeam({ name: teamName });
                enqueueSnackbar(`Business team created successfully`, {
                    variant: 'success',
                    preventDuplicate: true,
                });
            }
            handleCloseModal();
            debouncedFetchBusinessTeamsList(page + 1, rowsPerPage, sortBy, sortDir);
        } catch (error) {
            if (error?.details?.violations) {
                error.details.violations.violations.forEach((violation: unknown) => {
                    enqueueSnackbar(`${violation.propertyPath}: ${violation.title}`, {
                        variant: 'error',
                        preventDuplicate: true,
                    });
                });
            } else {
                enqueueSnackbar(`${error?.title || 'Error occurred'}`, { variant: 'error', preventDuplicate: true });
            }
            console.error('Error saving business team:', error);
        }
    };

    const fetchBusinessTeamsFullList = async (): Promise<void> => {
        try {
            const list = await getBusinessTeamsList({ perPage: 1000 });
            dispatch(setBusinessTeamsFullList(list));
        } catch (error) {
            enqueueSnackbar(`${error?.title}`, { variant: 'error', preventDuplicate: true });
            console.error('Error fetching business teams list:', error);
        }
    };

    const handleRevoke = async (teamId: number): void => {
        try {
            await revokeBusinessTeam(teamId);
            enqueueSnackbar(`Business team revoked successfully`, {
                variant: 'success',
                preventDuplicate: true,
            });
            fetchBusinessTeamsFullList();
            debouncedFetchBusinessTeamsList(page + 1, rowsPerPage, sortBy, sortDir);
        } catch (error) {
            enqueueSnackbar(`${error?.title || 'Error occurred'}`, { variant: 'error', preventDuplicate: true });
            console.error('Error saving business team:', error);
        }
    };

    const handleRestore = async (teamId: number): void => {
        try {
            await restoreBusinessTeam(teamId);
            enqueueSnackbar(`Business team restored successfully`, {
                variant: 'success',
                preventDuplicate: true,
            });
            fetchBusinessTeamsFullList();
            debouncedFetchBusinessTeamsList(page + 1, rowsPerPage, sortBy, sortDir);
        } catch (error) {
            enqueueSnackbar(`${error?.title || 'Error occurred'}`, { variant: 'error', preventDuplicate: true });
            console.error('Error saving business team:', error);
        }
    };

    return (
        <div style={{ padding: '25px' }}>
            <Paper style={{ padding: '25px' }}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        marginBottom: '15px',
                        gap: '15px',
                    }}
                >
                    <CustomButton label="+ Create a new team" onClick={() => handleOpenModal(false)} />
                </div>
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', padding: '25px' }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {['id', 'name'].map((column) => (
                                            <TableCell style={{ border: '1px solid #030F2B' }} key={column}>
                                                {['id', 'name'].includes(column) ? (
                                                    <TableSortLabel
                                                        active={sortBy === column}
                                                        direction={sortBy === column ? sortDir : 'asc'}
                                                        onClick={() => handleSortChange(column)}
                                                    >
                                                        {column.charAt(0).toUpperCase() + column.slice(1)}
                                                    </TableSortLabel>
                                                ) : (
                                                    column.charAt(0).toUpperCase() + column.slice(1)
                                                )}
                                            </TableCell>
                                        ))}
                                        <TableCell style={{ border: '1px solid #030F2B' }}>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {businessTeamsList.items.map((team) => (
                                        <TableRow key={team.id}>
                                            <TableCell style={{ border: '1px solid #B1BACE' }}>{team.id}</TableCell>
                                            <TableCell style={{ border: '1px solid #B1BACE' }}>{team.name}</TableCell>
                                            <TableCell style={{ border: '1px solid #B1BACE' }}>
                                                <CustomButton
                                                    label="Edit"
                                                    onClick={() => handleOpenModal(true, team.name, team.id)}
                                                />
                                                {team.isActive ? (
                                                    <CustomButton
                                                        label="Archive"
                                                        onClick={() => {
                                                            handleRevoke(team.id);
                                                        }}
                                                    />
                                                ) : (
                                                    <CustomButton
                                                        label="Restore"
                                                        onClick={() => {
                                                            handleRestore(team.id);
                                                        }}
                                                    />
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={businessTeamsList.totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                )}
                <Dialog open={open} onClose={handleCloseModal}>
                    <DialogTitle>{isEditing ? `Edit ${teamName}` : 'Create a new business team'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {isEditing
                                ? 'Edit the details of the business team.'
                                : 'Enter the name of the new business team.'}
                        </DialogContentText>
                        <TextField
                            margin="dense"
                            label="Team Name"
                            type="text"
                            fullWidth
                            value={teamName}
                            onChange={(e) => setTeamName(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseModal} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleSave} color="primary">
                            {isEditing ? 'Save' : 'Create'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Paper>
        </div>
    );
};

export default BusinessTeams;
