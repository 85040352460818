import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Configurations, ConfigurationsList } from '@/types';

export const getDefaultCurrentConfiguration = (): unknown => ({
    id: null,
    templateResource: null,
    versionId: null,
    status: null,
    isEditable: false,
    details: {
        technicalName: { value: null, loading: false, success: false, error: false },
        displayName: { value: null, loading: false, success: false, error: false },
        category: { value: null, loading: false, success: false, error: false },
        description: { value: null, loading: false, success: false, error: false },
        fileFormat: { value: null, loading: false, success: false, error: false },
        version: { value: null, loading: false, success: false, error: false },
        versionComment: { value: null, loading: false, success: false, error: false },
    },
    fileConfig: {
        enclosure: { value: '', loading: false, success: false, error: false },
        encoding: { value: '', loading: false, success: false, error: false },
        escape: { value: '', loading: false, success: false, error: false },
        delimiter: { value: '', loading: false, success: false, error: false },
        skipFirstLine: { value: false, loading: false, success: false, error: false },
        skipEmptyLines: { value: false, loading: false, success: false, error: false },
    },
    columns: [],
    rulesRegular: [],
    rulesCustom: [],
    bigQuery: {
        table: '',
        properties: [],
        dataset: '',
    },
    users: [],
    teams: [],
    fileTemplate: null,
    schemaResource: null,
    sendingDataToBQ: null,
    errorsNumber: null,
    validationErrors: null,
});

const initialState: Configurations = {
    configurations: {
        list: {
            items: [],
            currentPageNumber: 0,
            numItemsPerPage: 0,
            totalCount: 0,
        },
        fullList: {
            items: [],
            currentPageNumber: 0,
            numItemsPerPage: 0,
            totalCount: 0,
        },
        draftList: {
            items: [],
            currentPageNumber: 0,
            numItemsPerPage: 0,
            totalCount: 0,
        },
        categoriesList: {
            items: [],
            currentPageNumber: 0,
            numItemsPerPage: 0,
            totalCount: 0,
        },
        categoriesFullList: {
            items: [],
            currentPageNumber: 0,
            numItemsPerPage: 0,
            totalCount: 0,
        },
        versionList: {
            items: [],
            currentPageNumber: 0,
            numItemsPerPage: 0,
            totalCount: 0,
        },
        uploadHistoryList: {
            items: [],
            currentPageNumber: 0,
            numItemsPerPage: 0,
            totalCount: 0,
        },
        currentConfiguration: getDefaultCurrentConfiguration(),
        activeConfiguration: getDefaultCurrentConfiguration(),
    },
};

const configurationsSlice = createSlice({
    name: 'configurations',
    initialState,
    reducers: {
        setConfigurationSchemaResource: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.currentConfiguration.schemaResource = action.payload;
        },
        setConfigurationFileTemplate: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.currentConfiguration.fileTemplate = action.payload;
        },
        setConfigurationVersionList: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.versionList = action.payload;
        },
        setConfigurationUploadHistoryList: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.uploadHistoryList = action.payload;
        },
        setActiveConfiguration: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.activeConfiguration = action.payload;
        },
        setActiveConfigurationErrorsNumber: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.activeConfiguration.errorsNumber = action.payload;
        },
        setActiveConfigurationId: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.activeConfiguration.id = action.payload;
        },
        setActiveConfigurationTemplateResource: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.activeConfiguration.templateResource = action.payload;
        },
        setActiveConfigurationVersionId: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.activeConfiguration.versionId = action.payload;
        },
        setActiveConfigurationReferenceVersion: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.activeConfiguration.referenceVersion = action.payload;
        },
        setActiveConfigurationHighestVersion: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.activeConfiguration.highestVersion = action.payload;
        },
        setActiveConfigurationIsEditable: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.activeConfiguration.isEditable = action.payload;
        },
        setActiveConfigurationStatus: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.activeConfiguration.status = action.payload;
        },
        setActiveConfigurationTeams: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.activeConfiguration.teams = action.payload;
        },
        setActiveConfigurationUsers: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.activeConfiguration.users = action.payload;
        },
        setActiveConfigurationDetails: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.activeConfiguration.details = action.payload;
        },
        setActiveConfigurationFileConfig: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.activeConfiguration.fileConfig = action.payload;
        },
        setActiveConfigurationColumns: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.activeConfiguration.columns = action.payload;
        },
        setActiveConfigurationRulesRegular: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.activeConfiguration.rulesRegular = action.payload;
        },
        setActiveConfigurationRulesCustom: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.activeConfiguration.rulesCustom = action.payload;
        },
        setActiveConfigurationSendingDataToBQ: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.activeConfiguration.sendingDataToBQ = action.payload;
        },
        setActiveConfigurationValidationErrors: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.activeConfiguration.validationErrors = action.payload;
        },
        setActiveConfigurationBQColumns: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.activeConfiguration.bigQuery.properties = action.payload;
        },
        setActiveConfigurationBQTable: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.activeConfiguration.bigQuery.table = action.payload;
        },
        setActiveConfigurationBQDataset: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.activeConfiguration.bigQuery.dataset = action.payload;
        },
        setCurrentConfiguration: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.currentConfiguration = action.payload;
        },
        setCurrentConfigurationErrorsNumber: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.currentConfiguration.errorsNumber = action.payload;
        },
        setCurrentConfigurationId: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.currentConfiguration.id = action.payload;
        },
        setCurrentConfigurationTemplateResource: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.currentConfiguration.templateResource = action.payload;
        },
        setCurrentConfigurationVersionId: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.currentConfiguration.versionId = action.payload;
        },
        setCurrentConfigurationReferenceVersion: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.currentConfiguration.referenceVersion = action.payload;
        },
        setCurrentConfigurationHighestVersion: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.currentConfiguration.highestVersion = action.payload;
        },
        setCurrentConfigurationIsEditable: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.currentConfiguration.isEditable = action.payload;
        },
        setCurrentConfigurationStatus: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.currentConfiguration.status = action.payload;
        },
        setCurrentConfigurationTeams: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.currentConfiguration.teams = action.payload;
        },
        setCurrentConfigurationUsers: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.currentConfiguration.users = action.payload;
        },
        setCurrentConfigurationDetails: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.currentConfiguration.details = action.payload;
        },
        setCurrentConfigurationFileConfig: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.currentConfiguration.fileConfig = action.payload;
        },
        setCurrentConfigurationColumns: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.currentConfiguration.columns = action.payload;
        },
        setCurrentConfigurationRulesRegular: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.currentConfiguration.rulesRegular = action.payload;
        },
        setCurrentConfigurationRulesCustom: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.currentConfiguration.rulesCustom = action.payload;
        },
        setCurrentConfigurationSendingDataToBQ: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.currentConfiguration.sendingDataToBQ = action.payload;
        },
        setCurrentConfigurationValidationErrors: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.currentConfiguration.validationErrors = action.payload;
        },
        setCurrentConfigurationBQColumns: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.currentConfiguration.bigQuery.properties = action.payload;
        },
        setCurrentConfigurationBQTable: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.currentConfiguration.bigQuery.table = action.payload;
        },
        setCurrentConfigurationBQDataset: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.currentConfiguration.bigQuery.dataset = action.payload;
        },
        setConfigurationsList: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.list = action.payload;
        },
        setConfigurationsFullList: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.fullList = action.payload;
        },
        setConfigurationsDraftList: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.draftList = action.payload;
        },
        setConfigurationsCategoriesList: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.categoriesList = action.payload;
        },
        setConfigurationsCategoriesFullList: (state, action: PayloadAction<ConfigurationsList>) => {
            state.configurations.categoriesFullList = action.payload;
        },
        resetCurrentConfiguration: (state) => {
            state.configurations.currentConfiguration = getDefaultCurrentConfiguration();
        },
    },
});

export const {
    setConfigurationSchemaResource,
    setConfigurationUploadHistoryList,
    setConfigurationFileTemplate,
    setConfigurationVersionList,
    setActiveConfiguration,
    setActiveConfigurationErrorsNumber,
    setActiveConfigurationId,
    setActiveConfigurationTemplateResource,
    setActiveConfigurationVersionId,
    setActiveConfigurationReferenceVersion,
    setActiveConfigurationHighestVersion,
    setActiveConfigurationIsEditable,
    setActiveConfigurationStatus,
    setActiveConfigurationTeams,
    setActiveConfigurationUsers,
    setActiveConfigurationDetails,
    setActiveConfigurationFileConfig,
    setActiveConfigurationColumns,
    setActiveConfigurationRulesRegular,
    setActiveConfigurationRulesCustom,
    setActiveConfigurationSendingDataToBQ,
    setActiveConfigurationValidationErrors,
    setActiveConfigurationBQColumns,
    setActiveConfigurationBQTable,
    setActiveConfigurationBQDataset,
    setCurrentConfiguration,
    setCurrentConfigurationId,
    setCurrentConfigurationErrorsNumber,
    setCurrentConfigurationTemplateResource,
    setCurrentConfigurationSendingDataToBQ,
    setCurrentConfigurationValidationErrors,
    setCurrentConfigurationVersionId,
    setCurrentConfigurationIsEditable,
    setCurrentConfigurationStatus,
    setCurrentConfigurationReferenceVersion,
    setCurrentConfigurationHighestVersion,
    setCurrentConfigurationTeams,
    setCurrentConfigurationUsers,
    setCurrentConfigurationDetails,
    setCurrentConfigurationFileConfig,
    setCurrentConfigurationColumns,
    setCurrentConfigurationRulesRegular,
    setCurrentConfigurationRulesCustom,
    setCurrentConfigurationBQColumns,
    setCurrentConfigurationBQTable,
    setCurrentConfigurationBQDataset,
    setConfigurationsList,
    setConfigurationsFullList,
    setConfigurationsDraftList,
    setConfigurationsCategoriesList,
    setConfigurationsCategoriesFullList,
    resetCurrentConfiguration,
} = configurationsSlice.actions;

export const getConfigurationUploadHistoryListFromState = (state: {
    configurations: Configurations;
}): ConfigurationsList => state.configurations.configurations.uploadHistoryList;
export const getConfigurationVersionListFromState = (state: { configurations: Configurations }): ConfigurationsList =>
    state.configurations.configurations.versionList;
export const getConfigurationsCurrentConfiguration = (state: { configurations: Configurations }): ConfigurationsList =>
    state.configurations.configurations.currentConfiguration;
export const getConfigurationsActiveConfiguration = (state: { configurations: Configurations }): ConfigurationsList =>
    state.configurations.configurations.activeConfiguration;
export const getConfigurationsListFromState = (state: { configurations: Configurations }): ConfigurationsList =>
    state.configurations.configurations.list;
export const getConfigurationsFullListFromState = (state: { configurations: Configurations }): ConfigurationsList =>
    state.configurations.configurations.fullList;
export const getConfigurationsDraftListFromState = (state: { configurations: Configurations }): ConfigurationsList =>
    state.configurations.configurations.draftList;
export const getConfigurationsCategoriesListFromState = (state: {
    configurations: Configurations;
}): ConfigurationsList => state.configurations.configurations.categoriesList;
export const getConfigurationsCategoriesFullListFromState = (state: {
    configurations: Configurations;
}): ConfigurationsList => state.configurations.configurations.categoriesFullList;

export default configurationsSlice.reducer;
