import React, { useRef, useState, useEffect } from 'react';
import {
    Button,
    CircularProgress,
    TextField,
    Box,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Grid,
} from '@mui/material';
import { useFilesApi } from '@/api-client/files-api';
import { useSnackbar } from 'notistack';
import { useConfigurationsApi } from '@/api-client/configurations-api';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@/store';
import {
    getConfigurationsCurrentConfiguration,
    setCurrentConfigurationTemplateResource,
} from '@/store/configurationsSlice';

interface Props {
    isDraft: boolean;
    configurationId: string | null;
}

const FileUpload: React.FC<Props> = ({ isDraft, configurationId }) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [uploadedFileInfo, setUploadedFileInfo] = useState<string | null>(null);
    const [uploadedFileUuid, setUploadedFileUuid] = useState<string | null>(null);
    const [isGenerating, setIsGenerating] = useState(false);
    const [overwriteModalOpen, setOverwriteModalOpen] = useState(false);
    const [pendingAction, setPendingAction] = useState<'upload' | 'generate' | null>(null);
    const { uploadFile } = useFilesApi();
    const {
        postDraftConfigurationTemplate,
        removeDraftConfigurationTemplate,
        postConfigurationTemplate,
        removeConfigurationTemplate,
        postDraftConfigurationTemplateFileGenerate,
        postConfigurationTemplateFileGenerate,
    } = useConfigurationsApi();
    const fileInput = useRef<HTMLInputElement | null>(null);
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams<{ id: string }>();
    const dispatch: AppDispatch = useDispatch();
    const configuration = useSelector(getConfigurationsCurrentConfiguration);

    useEffect(() => {
        if (configuration.templateResource?.templateUuid) {
            setUploadedFileUuid(configuration.templateResource.templateUuid);
            setUploadedFileInfo(`Template file with extension ${configuration.templateResource.templateExtension}`);
        }
    }, [configuration.templateResource]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.files && event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
            event.target.value = '';
            if (uploadedFileInfo) {
                setPendingAction('upload');
                setOverwriteModalOpen(true);
            } else {
                handleUpload();
            }
        }
    };

    const handleUpload = async (): Promise<void> => {
        if (selectedFile) {
            try {
                const response = await uploadFile(selectedFile, 'template');
                setUploadedFileInfo(`Configuration by ${response.ownerId} ${response.createdAt}`);
                setUploadedFileUuid(response.uuid);
                setSelectedFile(null);

                if (isDraft) {
                    await postDraftConfigurationTemplate(configurationId || id, response.uuid);
                } else {
                    await postConfigurationTemplate(configurationId || id, response.uuid);
                }
                dispatch(
                    setCurrentConfigurationTemplateResource({
                        templateExtension: response.extension,
                        templateUuid: response.uuid,
                    }),
                );
                enqueueSnackbar('Template uploaded successfully', { variant: 'success', preventDuplicate: true });
            } catch (error) {
                enqueueSnackbar(`${error?.title}`, { variant: 'error', preventDuplicate: true });
                console.error('File upload failed', error);
            }
        }
    };

    const handleRemove = async (): Promise<void> => {
        if (uploadedFileUuid) {
            try {
                if (isDraft) {
                    await removeDraftConfigurationTemplate(configurationId || id);
                } else {
                    await removeConfigurationTemplate(configurationId || id);
                }
                setUploadedFileInfo(null);
                setUploadedFileUuid(null);
                dispatch(setCurrentConfigurationTemplateResource({ templateExtension: '', templateUuid: '' }));
                enqueueSnackbar('File removed successfully', { variant: 'success', preventDuplicate: true });
            } catch (error) {
                enqueueSnackbar(`${error?.title}`, { variant: 'error', preventDuplicate: true });
                console.error('File removal failed', error);
            }
        }
    };

    const handleGenerate = async (): Promise<void> => {
        if (uploadedFileInfo) {
            setPendingAction('generate');
            setOverwriteModalOpen(true);
            return;
        }

        await executeGenerate();
    };

    const executeGenerate = async (): Promise<void> => {
        setIsGenerating(true);
        try {
            if (isDraft) {
                const response = await postDraftConfigurationTemplateFileGenerate(configurationId || id);

                dispatch(
                    setCurrentConfigurationTemplateResource({
                        templateExtension: response.extension,
                        templateUuid: response.uuid,
                    }),
                );

                enqueueSnackbar(`File generated successfully`, { variant: 'success', preventDuplicate: true });
            } else {
                const response = await postConfigurationTemplateFileGenerate(configurationId || id);

                const editableResource = response.editableVersionTemplateFileResource;
                const activeResource = response.activeVersionTemplateFileResource;

                if (editableResource) {
                    dispatch(
                        setCurrentConfigurationTemplateResource({
                            templateExtension: editableResource.extension,
                            templateUuid: editableResource.uuid,
                        }),
                    );
                    enqueueSnackbar(`Editable version generated`, { variant: 'success', preventDuplicate: true });
                }

                if (activeResource) {
                    dispatch(
                        setCurrentConfigurationTemplateResource({
                            templateExtension: activeResource.extension,
                            templateUuid: activeResource.uuid,
                        }),
                    );
                    enqueueSnackbar(`Active version generated`, { variant: 'success', preventDuplicate: true });
                }

                if (!editableResource && !activeResource) {
                    enqueueSnackbar('No file resources were generated.', { variant: 'info', preventDuplicate: true });
                }
            }
        } catch (error) {
            enqueueSnackbar(`Your configuration doesn't have any data to generate in the template`, {
                variant: 'error',
                preventDuplicate: true,
            });
            console.error('File generation failed', error);
        } finally {
            setIsGenerating(false);
        }
    };

    const handleDialogConfirm = async (): Promise<void> => {
        setOverwriteModalOpen(false);

        if (pendingAction === 'upload') {
            await handleUpload();
        } else if (pendingAction === 'generate') {
            await executeGenerate();
        }

        setPendingAction(null);
    };

    const handleDialogCancel = (): void => {
        setOverwriteModalOpen(false);
        setPendingAction(null);
        setSelectedFile(null);
    };

    return (
        <Box mt={5}>
            <Grid
                container
                spacing={3}
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Grid item xs={6}>
                    {uploadedFileInfo && (
                        <Box mb={2} style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body1">
                                Currently uploaded file: &quot;{uploadedFileInfo}&quot;
                            </Typography>
                            <Button variant="outlined" color="error" onClick={handleRemove} sx={{ ml: 2 }}>
                                Remove
                            </Button>
                        </Box>
                    )}
                    <Typography variant="body1">Upload a new file</Typography>
                    <Box mt={1} mb={3} style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="file"
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                            id="file-input"
                            ref={fileInput}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => fileInput.current?.click()}
                            sx={{ mr: 2 }}
                        >
                            Browse...
                        </Button>
                        <TextField
                            size="small"
                            value={selectedFile ? selectedFile.name : 'Upload the template from your computer...'}
                            InputProps={{ readOnly: true }}
                            sx={{ width: '300px', mr: 2 }}
                        />
                        <Button variant="contained" color="primary" onClick={handleUpload} disabled={!selectedFile}>
                            Upload
                        </Button>
                    </Box>
                    <Typography variant="body1">or generating a template basing on your configuration</Typography>
                    <Box mt={1}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleGenerate}
                            disabled={isGenerating}
                            startIcon={isGenerating && <CircularProgress size={20} />}
                        >
                            Generate
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            <Dialog open={overwriteModalOpen} onClose={handleDialogCancel}>
                <DialogTitle>Confirm Overwrite</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        The file is already attached to this configuration. This action will overwrite the current file.
                        Are you sure?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogCancel} color="primary">
                        No
                    </Button>
                    <Button onClick={handleDialogConfirm} color="primary">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default FileUpload;
