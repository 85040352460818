export interface Config {
    JS_REACT_APP_BACKEND_URI: string;
    JS_REACT_APP_ISSUER: string;
    JS_REACT_APP_CLIENT_ID: string;
}

export enum AVAILABLE_ROLES {
    ROLE_PLATFORM_ADMIN = 'ROLE_PLATFORM_ADMIN',
    ROLE_ADMIN = 'ROLE_ADMIN',
    ROLE_USER = 'ROLE_USER',
}

export enum CONFIGURATIONS_CATEGORY_STATUS {
    ARCHIVE = 'archive',
    ACTIVE = 'active',
}

export enum CONFIGURATIONS_CATEGORY_STATUS_ALL {
    ARCHIVE = 'Archived',
    ACTIVE = 'Active',
}

export enum BUSINESS_TEAMS {
    BUSINESS_TEAM_1 = 'BusinessTeam:MarketingProject',
    BUSINESS_TEAM_2 = 'BusinessTeam:DiorFinance',
    BUSINESS_TEAM_3 = 'BusinessTeam:SalesTeam',
}

export interface BusinessTeam {
    id: number;
    name: string;
    isActive: boolean;
}

export interface User {
    id?: number;
    email: string;
    firstName: string;
    lastName: string;
    role: AVAILABLE_ROLES | string;
    isActive?: boolean;
    locale?: string;
    timeZone?: string;
    businessTeams: Array<BusinessTeam>;
}

export interface UsersList {
    items: User[];
    currentPageNumber: 0;
    numItemsPerPage: 0;
    totalCount: 0;
}

export interface Users {
    users: {
        user: User;
        fullList: UsersList;
        list: UsersList;
        revoked: UsersList;
        admins: UsersList;
        businessUserList: Array<unknown>;
    };
}

export interface BusinessTeam {
    id: number;
    name: string;
    isActive: boolean;
}

export interface BusinessTeams {
    businessTeams: {
        list: BusinessTeamsList;
        fullList: BusinessTeamsList;
    };
}

export interface BusinessTeamsList {
    items: BusinessTeam[];
    currentPageNumber: 0;
    numItemsPerPage: 0;
    totalCount: 0;
}

export interface Configuration {
    id: number;
    ownerId: number;
    displayName: string;
    uuid: string;
    status: string;
    isDraft: boolean;
}

export interface ConfigurationsCategories {
    id: number;
    name: string;
    status: string;
    uuid: string;
}

interface DraftConfiguration {
    id: number;
    ownerId: number;
    displayName: string;
    version: string;
    uuid: string;
    status: string;
    isDraft: boolean;
    details: {
        name: string;
        description: string;
        fileType: string;
        category: {
            id: number;
            name: string;
            status: string;
            uuid: string;
        };
    };
    fileOptions: {
        encoding: string;
        delimiter: string;
        enclosure: string;
        escape: string;
        skipFirstLine: boolean;
        skipEmptyLines: boolean;
    };
    sendingOptions: {
        type: string;
    };
    schema: {
        columns: Array<null>;
        rules: Array<null>;
    };
}

export interface ConfigurationsList {
    items: Configuration[] | DraftConfiguration[] | ConfigurationsCategories[] | ConfigurationResource[];
    currentPageNumber: 0;
    numItemsPerPage: 0;
    totalCount: 0;
}

export interface Configurations {
    configurations: {
        list: ConfigurationsList;
        fullList: ConfigurationsList;
        draftList: ConfigurationsList;
        categoriesList: ConfigurationsList;
        categoriesFullList: ConfigurationsList;
        versionList: ConfigurationsList;
        uploadHistoryList: ConfigurationsList;
        currentConfiguration: ConfigurationsList;
        activeConfiguration: ConfigurationsList;
    };
}

export interface PaginatedResponse<T> {
    items: T[];
    currentPageNumber: number;
    numItemsPerPage: number;
    totalCount: number;
}

// types.ts
export interface CategoryResource {
    id: number;
    name: string;
    status: string;
    uuid: string;
}

export interface ConfigurationResource {
    id: number;
    ownerId: number;
    name: string;
    uuid: string;
    status: string;
    tempVersion: string | null;
    categoryResource: CategoryResource;
}

export interface DetailsResource {
    name: string;
    description: string;
    fileType: string;
}

export interface FileOptionsResource {
    encoding: string;
    delimiter: string;
    enclosure: string;
    escape: string;
    skipFirstLine: boolean;
    skipEmptyLines: boolean;
}

export interface SchemaResource {
    columns: never[];
    rules: never[];
}

export interface SendingOptionsResource {
    type: string;
}

export interface VersionResources {
    version: string;
    status: string;
    categoryResource: CategoryResource;
    detailsResource: DetailsResource;
    fileOptionsResource: FileOptionsResource;
    schemaResource: SchemaResource;
    sendingOptionsResource: SendingOptionsResource;
}

export interface ConfigurationResponse {
    configurationResource: ConfigurationResource;
    versionResources: VersionResources;
    isEditable: boolean;
}

export interface Rule {
    name: string;
    description: string;
    condition: string;
    isValidated: boolean;
    isEditing: boolean;
    loading: boolean;
    success: boolean;
    error: boolean;
    group: string | null;
}

export interface FileUploadResponse {
    createdAt: string;
    uuid: string;
    mimeType: string;
    type: 'avatar' | 'configuration' | 'template';
    ownerId: number;
    extension: string;
    uri: string;
}

export interface ActivityLog {
    id: number;
    createdAt: string;
    userResource: {
        id: number;
        email: string;
        firstName: string;
        lastName: string;
        role: string;
        isActive: boolean;
        locale: string;
        timeZone: string;
        businessTeams: Array<{
            id: number;
            name: string;
        }>;
    };
    message: string;
    context: Array<string | null>;
    entityId: number;
    entityName: string;
}

export interface ActivityLogsList {
    items: ActivityLog[];
    currentPageNumber: number;
    numItemsPerPage: number;
    totalCount: number;
}

export interface ActivityLogsRequirements {
    [key: string]: string[];
}

// export type BusinessTeamsList = PaginatedResponse<BusinessTeam>;
