import React, { ChangeEvent, useMemo, useState } from 'react';
import { Grid, Button, IconButton } from '@mui/material';
import { Edit, Delete, Check, Add } from '@mui/icons-material';
import { ConfigurationsList, Rule } from '@/types';
import { useDispatch } from 'react-redux';
import { setCurrentConfigurationRulesRegular, setCurrentConfigurationRulesCustom } from '@/store/configurationsSlice';
import { AppDispatch } from '@/store';
import { useConfigurationsApi } from '@/api-client/configurations-api';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useCloudFunctionsApi } from '@/api-client/cloud-functions-api';
import debounce from 'lodash/debounce';
import CustomTextField from '@/components/custom-text-field';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

interface Props {
    isDraft: boolean;
    configurationId: string | null;
    group: string | null;
    configuration: ConfigurationsList;
}

const RulesTable: React.FC<Props> = ({ isDraft, configurationId, group, configuration }) => {
    const dispatch: AppDispatch = useDispatch();
    const {
        postDraftConfigurationRules,
        updateDraftConfigurationRules,
        removeDraftConfigurationRules,
        updateConfigurationRules,
        postConfigurationRules,
        removeConfigurationRules,
    } = useConfigurationsApi();
    const { postCloudFunctionRuleValidate } = useCloudFunctionsApi();
    const { id } = useParams<{ id: string }>();
    const { enqueueSnackbar } = useSnackbar();

    const rules = group === 'regular' ? configuration.rulesRegular : configuration.rulesCustom;
    const title = group === 'regular' ? 'Rules' : 'Custom Functions';
    const conditionTitle = group === 'regular' ? 'Condition' : 'Body';

    const [values, setValues] = useState<string[]>(rules.map(() => ''));
    const [loading, setLoading] = useState<boolean[]>(rules.map(() => false));

    const CustomWidthTooltip = useMemo(
        () =>
            styled(({ className, ...props }: TooltipProps) => (
                <Tooltip {...props} classes={{ popper: className }} arrow />
            ))({
                [`& .${tooltipClasses.tooltip}`]: {
                    maxWidth: 180,
                },
            }),
        [],
    );

    const setRulesAction =
        group === 'regular' ? setCurrentConfigurationRulesRegular : setCurrentConfigurationRulesCustom;

    const handleValueChange = (index: number, newValue: string): void => {
        const updatedValues = [...values];
        updatedValues[index] = newValue;
        setValues(updatedValues);
    };

    const handleCheckRule = async (index: number): Promise<void> => {
        if (loading[index]) return;

        const updatedLoading = [...loading];
        updatedLoading[index] = true;
        setLoading(updatedLoading);

        const rule = rules[index];
        const value = values[index];
        const expression = rule.condition;
        const ruleId = rule.id;
        const type = isDraft ? 'draft' : 'published';

        try {
            const response = await postCloudFunctionRuleValidate({ value, expression, ruleId, type });
            const { resource, executionResult, errorMessage } = response;

            if (resource.isValidated) {
                if (executionResult) {
                    enqueueSnackbar('Condition is valid and execution is valid', {
                        variant: 'info',
                        preventDuplicate: true,
                    });
                } else {
                    enqueueSnackbar('Condition is valid but execution is not valid', {
                        variant: 'info',
                        preventDuplicate: true,
                    });
                }
            }

            if (errorMessage) {
                enqueueSnackbar(errorMessage, {
                    variant: 'error',
                    preventDuplicate: true,
                });
            }

            const updatedRules = rules.map((rule, i) =>
                i === index ? { ...rule, isValidated: resource.isValidated } : rule,
            );
            dispatch(setRulesAction(updatedRules));
        } catch (error) {
            console.error(error);
            enqueueSnackbar('Error validating rule', {
                variant: 'error',
                preventDuplicate: true,
            });
        } finally {
            const updatedLoading = [...loading];
            updatedLoading[index] = false;
            setLoading(updatedLoading);
        }
    };

    const debouncedHandleCheckRule = debounce(handleCheckRule, 1000);

    const handleRuleChange = (index: number, field: keyof Rule, value: string): void => {
        const updatedRules = rules.map((rule, i) => (i === index ? { ...rule, [field]: value } : rule));
        dispatch(setRulesAction(updatedRules));
    };

    const handleEditRule = (index: number): void => {
        const updatedRules = rules.map((rule, i) => (i === index ? { ...rule, isEditing: true } : rule));
        dispatch(setRulesAction(updatedRules));
    };

    const handleSaveRule = async (index: number): Promise<void> => {
        const updatedRules = rules.map((rule, i) =>
            i === index ? { ...rule, isEditing: false, loading: true, error: false } : rule,
        );
        dispatch(setRulesAction(updatedRules));

        const rule = updatedRules[index];
        const ruleData = {
            name: rule.name,
            description: rule.description,
            condition: rule.condition,
            isValidated: rule.isValidated,
            group,
        };

        try {
            let newRule;
            if (rule.id) {
                const response = isDraft
                    ? await updateDraftConfigurationRules(configurationId || id, rule.id, ruleData)
                    : await updateConfigurationRules(configurationId || id, rule.id, ruleData);

                newRule = response.rules.find((r) => r.id === rule.id);
            } else {
                const response = isDraft
                    ? await postDraftConfigurationRules(configurationId || id, ruleData)
                    : await postConfigurationRules(configurationId || id, ruleData);

                newRule = response.rules.find(
                    (r) => r.name === rule.name && r.description === rule.description && r.condition === rule.condition,
                );

                const updatedRulesAfterPost = rules.map((rule, i) =>
                    i === index ? { ...rule, ...newRule, isEditing: false, loading: false, error: false } : rule,
                );
                dispatch(setRulesAction(updatedRulesAfterPost));
                return;
            }

            const updatedRulesAfterSave = rules.map((rule, i) =>
                i === index ? { ...rule, ...newRule, isEditing: false, loading: false, error: false } : rule,
            );
            dispatch(setRulesAction(updatedRulesAfterSave));
        } catch (error) {
            console.error(error);

            const updatedRulesAfterError = rules.map((rule, i) => {
                if (i === index) {
                    const errorFields: unknown = {};
                    if (error.details?.violations) {
                        error.details.violations.violations.forEach((violation: unknown) => {
                            const errorField = violation.propertyPath;
                            errorFields[errorField] = violation.title;
                            enqueueSnackbar(`${errorField}: ${violation.title}`, {
                                variant: 'error',
                                preventDuplicate: true,
                            });
                        });
                    } else if (error.details?.exception) {
                        const errorMessage = error.details.exception.message;
                        enqueueSnackbar(errorMessage, {
                            variant: 'error',
                            preventDuplicate: true,
                        });
                    }
                    return { ...rule, loading: false, error: errorFields };
                }
                return rule;
            });

            dispatch(setRulesAction(updatedRulesAfterError));
        }
    };

    const handleAddRule = (): void => {
        const newRule: Rule = {
            name: '',
            description: '',
            condition: '',
            isEditing: true,
            isValidated: false,
            loading: false,
            success: false,
            error: false,
            group,
        };
        dispatch(setRulesAction([...rules, newRule]));
    };

    const handleDeleteRule = async (index: number): Promise<void> => {
        try {
            const rule = rules[index];
            if (rule.id) {
                if (isDraft) {
                    await removeDraftConfigurationRules(configurationId || id, rule.id);
                } else {
                    await removeConfigurationRules(configurationId || id, rule.id);
                }
            }
            const updatedRules = rules.filter((_, i) => i !== index);
            dispatch(setRulesAction(updatedRules));
        } catch (error) {
            if (error.type === 'conflict') {
                enqueueSnackbar(`${error?.title}`, { variant: 'error', preventDuplicate: true });
            } else {
                enqueueSnackbar(`${error?.title || 'Error occurred'}`, { variant: 'error', preventDuplicate: true });
            }
        }
    };

    return (
        <>
            <Grid item xs={12}>
                <h4>{title}:</h4>
            </Grid>
            <Grid item xs={12}>
                <div style={{ overflow: 'auto' }}>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr style={{ textAlign: 'left' }}>
                                <th style={{ padding: '10px', border: '1px solid #B1BACE' }}>
                                    <h6>Name</h6>
                                </th>
                                <th style={{ padding: '10px', border: '1px solid #B1BACE' }}>
                                    <h6>Description</h6>
                                </th>
                                <th style={{ padding: '10px', border: '1px solid #B1BACE' }}>
                                    <h6>{conditionTitle}</h6>
                                </th>
                                <th style={{ padding: '10px', border: '1px solid #B1BACE' }}>
                                    <h6>Options</h6>
                                </th>
                                <th style={{ padding: '10px', border: '1px solid #B1BACE' }}>
                                    <h6>Condition check</h6>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {rules.map((rule, index) => (
                                <tr key={index}>
                                    <td style={{ padding: '10px', border: '1px solid #B1BACE' }}>
                                        <CustomTextField
                                            status={rule.error ? 'error' : null}
                                            value={rule.name || ''}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                handleRuleChange(index, 'name', e.target.value)
                                            }
                                            disabled={!rule.isEditing}
                                            readonly={!rule.isEditing}
                                        />
                                    </td>
                                    <td style={{ padding: '10px', border: '1px solid #B1BACE' }}>
                                        <CustomTextField
                                            status={rule.error ? 'error' : null}
                                            value={rule.description || ''}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                handleRuleChange(index, 'description', e.target.value)
                                            }
                                            disabled={!rule.isEditing}
                                            readonly={!rule.isEditing}
                                            multiline
                                            rows={6}
                                        />
                                    </td>
                                    <td style={{ padding: '10px', border: '1px solid #B1BACE' }}>
                                        <CustomTextField
                                            status={rule.error ? 'error' : null}
                                            value={rule.condition || ''}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                handleRuleChange(index, 'condition', e.target.value)
                                            }
                                            disabled={!rule.isEditing}
                                            readonly={!rule.isEditing}
                                            multiline
                                            rows={6}
                                        />
                                    </td>
                                    <td style={{ padding: '10px', border: '1px solid #B1BACE' }}>
                                        {rule.isEditing ? (
                                            <IconButton
                                                aria-label="save"
                                                color="primary"
                                                onClick={() => handleSaveRule(index)}
                                            >
                                                <Check />
                                            </IconButton>
                                        ) : (
                                            <IconButton
                                                aria-label="edit"
                                                color="primary"
                                                onClick={() => handleEditRule(index)}
                                            >
                                                <Edit />
                                            </IconButton>
                                        )}
                                        <IconButton
                                            aria-label="delete"
                                            color="secondary"
                                            onClick={() => handleDeleteRule(index)}
                                        >
                                            <Delete />
                                        </IconButton>
                                    </td>
                                    <td style={{ padding: '10px', border: '1px solid #B1BACE' }}>
                                        <CustomTextField
                                            value={values[index] || ''}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                handleValueChange(index, e.target.value)
                                            }
                                            disabled={rule.isEditing}
                                            readonly={rule.isEditing}
                                            multiline
                                            rows={6}
                                        />
                                        <CustomWidthTooltip
                                            open={!rule.isValidated && !rule.isEditing}
                                            title="Please check this rule before publishing the configuration"
                                        >
                                            <Button
                                                style={{ marginTop: '10px' }}
                                                variant="outlined"
                                                color="primary"
                                                onClick={() => debouncedHandleCheckRule(index)}
                                                disabled={rule.isEditing || loading[index]}
                                                endIcon={
                                                    rule.isValidated ? (
                                                        <CheckCircleIcon style={{ color: 'green' }} />
                                                    ) : (
                                                        ''
                                                    )
                                                }
                                            >
                                                {loading[index] ? 'Checking...' : 'Check'}
                                            </Button>
                                        </CustomWidthTooltip>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" color="primary" startIcon={<Add />} onClick={handleAddRule}>
                    Add Rule
                </Button>
            </Grid>
        </>
    );
};

export default RulesTable;
