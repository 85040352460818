import React from 'react';
import { makeStyles } from '@mui/styles';

type CustomButtonProps = {
    onClick?: () => void;
    disabled?: boolean;
    label: string;
    type?: 'button' | 'reset' | 'submit' | undefined;
    style?: React.CSSProperties;
};

const useStyles = makeStyles({
    button: {
        display: 'inline-block',
        margin: '0 1px',
        padding: '5px 10px',
        fontSize: '12px',
        color: '#0E1C34',
        border: 'none',
        cursor: 'pointer',
        background: 'transparent',
        borderBottom: '1px solid #0E1C34',
        whiteSpace: 'nowrap',
        '&:hover': {
            opacity: '0.7',
        },
        '&:disabled': {
            cursor: 'not-allowed',
            color: '#ccc',
        },
    },
});

const CustomButton: React.FC<CustomButtonProps> = ({ onClick, disabled = false, label, type = 'button', style }) => {
    const classes = useStyles();

    return (
        <button onClick={onClick} disabled={disabled} className={classes.button} type={type} style={style}>
            {label}
        </button>
    );
};

export default CustomButton;
